export const socialIconList = [
  {
    name: 'github',
    prefix: 'fab',
    link: 'https://github.com/cad324',
  },
  {
    name: 'linkedin',
    prefix: 'fab',
    link: 'https://www.linkedin.com/in/clive-duncan-59322b190'
  }
  // Add further social links with the icon of choice and link here
  // check https://fontawesome.com/icons?d=gallery&s=brands for other brand icons
];
